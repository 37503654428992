import React from "react"
import { graphql } from "gatsby"
import ConditionalLayout from "../components/ConditionalLayout"
import { theme } from "@singita/components"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import { useSeo } from "gatsby-singita-theme/src/hooks"
import { CONTENT_TYPES } from "../common/constants"
import { mapperWithFunction } from "../common/utils"
import { NavbarContext } from "gatsby-singita-theme/src/context/NavbarContext"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import MediaWrapper from "../modules/MediaWrapper"
import { Box } from "@singita/components"

const DATA_MAP = {
  title: ["title"],
  description: ["description"],
  image: ["thumbnail_url"],
  thumbnailUrl: ["thumbnail_url"],
  previewUrl: ["video_url"],
  type: ["type"],
  video: ["source"],
  uploadDate: ["upload_date"],
  width: ["width"],
  height: ["height"],
  creator: ["author_name"],
}

const VIDEO_MAP = {
  previewUrl: ["source"],
  thumbnailUrl: ["thumbnail_url"],
  name: ["title"],
  caption: ({ description, caption }) => {
    return description || caption || ""
  },
  uploadDate: ["upload_date"],
}

const VideoTemplate = props => {
  const {
    data: { video },
    location: { state },
  } = props

  const { isModal } = state || {}
  const seoProps = useSeo(props.pageContext.meta, DATA_MAP)
  const videoProps = mapperWithFunction(props, VIDEO_MAP)
  const jsonLd = getJsonLd(seoProps, TEMPLATE_TYPES.VIDEO)

  seoProps.title = `Video: ${seoProps.title}`
  const [filterOffset, setFilterOffset] = React.useState(0)
  const { navbarHeight, setNavbarStyles } = React.useContext(NavbarContext)

  React.useLayoutEffect(() => {
    setNavbarStyles({ bg: theme.colors.bgLighter })
  }, [])

  React.useLayoutEffect(() => {
    if (isModal) {
      const elem = document.getElementById("large-modal-header")
      if (elem) {
        const dims = elem.getBoundingClientRect()
        setFilterOffset(dims.height)
      }
    } else {
      setFilterOffset(navbarHeight)
    }
  }, [isModal, navbarHeight])
  return (
    <>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />

      <ConditionalLayout
        modalSize="large"
        modalTitle={seoProps.title}
        bg={theme.colors.brandBeige}
        pageContext={props.pageContext}
      >
        {_ => {
          return (
            <Box pt={[11]} sx={{ position: "relative" }}>
              <MediaWrapper
                {...video}
                __typename={CONTENT_TYPES.VIDEO_INLINE}
                role="presentation"
                style={{ width: "100%", height: "100%" }}
                imgStyle={{
                  objectFit: "cover",
                  userDrag: "none",
                  "-webkit-user-drag": "none",
                }}
                loading="eager"
              />
            </Box>
          )
        }}
      </ConditionalLayout>
    </>
  )
}

export default VideoTemplate

export const CONTENT_ASSET_QUERY = graphql`
  query contentfulContentAssetsDataJsonNode($id: String!) {
    video: contentfulContentAssetsDataJsonNode(id: { eq: $id }) {
      title
      source
      width
      height
      id
      type
      desc: caption
    }
  }
`
